import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {useMemo} from 'react';
import {StatusBar, StyleSheet} from 'react-native';

function useSize() {
  const {screenWidth, screenHeight, designWidth, calcActualSize} =
    useScreenSize();
  const size = useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      topHeaderSize: (screenWidth * 70) / designWidth,
      smallHeaderSize: (screenWidth * 32) / designWidth,
      topInnerHeaderSize: (screenWidth * 64) / designWidth,
      topBgHeight: (screenWidth * 260) / designWidth,
      walletIconSize: calcActualSize(40),
      topClipHeight: globalStore.isWeb
        ? calcActualSize(44)
        : StatusBar.currentHeight || 0,
      startTopUserHeight: (screenHeight * 40) / designWidth,
      topUserHeight: (screenHeight * 60) / designWidth,
      calcActualSize,
    };
  }, [screenWidth, screenHeight, designWidth, calcActualSize]);
  return size;
}

export function useInnerStyle() {
  const size = useSize();
  const opacityWhite = 'rgba(255, 255, 255, 0.3)';
  const uidRadius = 30;
  const meStyle = StyleSheet.create({
    page: {
      paddingTop: 0,
    },
    topBackground: {
      top: -size.topClipHeight,
      left: 0,
    },
    whiteArea: {
      height: 72,
    },
  });
  const userStyle = StyleSheet.create({
    headerWrap: {
      width: size.topHeaderSize,
      height: size.topHeaderSize,
      borderRadius: size.topHeaderSize / 2,
    },
    uid: {
      height: theme.iconSize.l,
      borderRadius: uidRadius,
      backgroundColor: opacityWhite,
    },
    copy: {
      width: theme.iconSize.l,
      height: theme.iconSize.l,
      backgroundColor: opacityWhite,
      borderRadius: uidRadius,
    },
  });
  const amountStyle = StyleSheet.create({
    outContainer: {
      height: 36 + size.calcActualSize(48 + 180),
    },
    item: {
      minWidth: 64,
    },
  });
  const listStyle = StyleSheet.create({
    new: {
      backgroundColor: '#FF4D4D',
      borderRadius: 20,
      paddingVertical: 2,
    },
  });
  const vipStyle = StyleSheet.create({
    opacity: {
      opacity: 0.5,
    },
    container: {
      top: 0,
      left: theme.paddingSize.s,
    },
  });
  return {
    size,
    meStyle,
    userStyle,
    amountStyle,
    listStyle,
    vipStyle,
  };
}
