import theme from '@style';
import React from 'react';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import globalStore from '@/services/global.state';
import LazyImage from '@basicComponents/image';
import {rechargeIcon, refreshIcon, withdrawIcon} from './me.variable';
import {useInnerStyle} from './me.hooks';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {IUserInfo} from '@/services/global.service';
import {goTo, toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';
import {BasicObject} from '@/types';
import {ReactComponent as RecordIcon} from '@/assets/icons/me/records-icon.svg';
const rightIcon = require('@components/assets/icons/me-list-item/right-icon.webp');

export interface MeAmountProps {
  login: boolean;
  user?: IUserInfo;
  onRefresh?: () => void;
}

const MeAmount: React.FC<MeAmountProps> = ({login, user, onRefresh}) => {
  const {i18n} = useTranslation();
  const {
    size: {walletIconSize, calcActualSize},
  } = useInnerStyle();
  const toRecharge = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const toWithdraw = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Withdraw');
  };

  const toRechargeRecord = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('RechargeRecords');
  };

  const toWithdrawRecord = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('WithdrawRecords');
  };
  return (
    <View
      style={[
        {
          paddingHorizontal: calcActualSize(12),
          paddingTop: calcActualSize(16),
          paddingBottom: calcActualSize(12),
          gap: calcActualSize(16),
        },
        theme.borderRadius.m,
        theme.background.white,
        theme.margin.btml,
      ]}>
      <NativeTouchableOpacity
        onPress={toRecharge}
        style={[
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          {
            paddingLeft: calcActualSize(12),
          },
        ]}>
        <View style={[theme.flex.col]}>
          <Text second calc fontSize={theme.fontSize.s}>
            {i18n.t('me.money.myWallet')}
          </Text>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <View style={[theme.flex.row, theme.flex.alignEnd]}>
              {login ? (
                <>
                  <Text
                    calc
                    fontFamily="fontDin"
                    blod
                    fontSize={theme.fontSize.m}
                    style={theme.margin.rightxxs}>
                    {globalStore.currency}
                  </Text>
                  <Text
                    calc
                    fontFamily="fontDin"
                    fontSize={20}
                    blod
                    style={[theme.margin.rightl]}>
                    {user
                      ? toPriceStr(user.userBalance, {
                          thousands: true,
                          showCurrency: false,
                        })
                      : '0'}
                  </Text>
                </>
              ) : (
                <Text
                  calc
                  fontFamily="fontDin"
                  fontSize={20}
                  blod
                  style={[theme.margin.rightl]}>
                  {'******'}
                </Text>
              )}
            </View>
            <NativeTouchableOpacity onPress={onRefresh}>
              <LazyImage
                occupancy="#0000"
                width={theme.iconSize.s}
                height={theme.iconSize.s}
                imageUrl={refreshIcon}
              />
            </NativeTouchableOpacity>
          </View>
        </View>
        <LazyImage
          occupancy={'transparent'}
          imageUrl={rightIcon}
          width={calcActualSize(14)}
          height={calcActualSize(14)}
        />
      </NativeTouchableOpacity>
      <View
        style={[
          theme.flex.row,
          theme.flex.between,
          {
            padding: calcActualSize(12),
            borderTopWidth: calcActualSize(1),
            borderTopColor: '#E8EAEE',
          },
          {
            background:
              'linear-gradient(180deg, #F4F6FA 0%, rgba(255, 255, 255, 0.00) 100%)',
          } as BasicObject,
        ]}>
        <View
          style={[
            theme.flex.flex,
            theme.flex.col,
            theme.flex.centerByCol,
            {gap: calcActualSize(12)},
          ]}>
          <NativeTouchableOpacity onPress={toRecharge}>
            <View
              style={[
                theme.flex.flex,
                theme.flex.row,
                theme.flex.centerByCol,
                {gap: calcActualSize(12)},
              ]}>
              <LazyImage
                imageUrl={rechargeIcon}
                width={walletIconSize}
                height={walletIconSize}
              />
              <Text calc main fontSize={14} fontWeight="700">
                {i18n.t('me.money.recharge')}
              </Text>
            </View>
          </NativeTouchableOpacity>
          <NativeTouchableOpacity onPress={toRechargeRecord}>
            <View
              style={[
                theme.flex.row,
                theme.flex.centerByCol,
                {gap: calcActualSize(4)},
              ]}>
              <RecordIcon
                width={calcActualSize(20)}
                height={calcActualSize(20)}
                style={{color: theme.fontColor.accent}}
              />
              <Text calc second fontSize={12}>
                {i18n.t('me.label.rechargeRecord')}
              </Text>
            </View>
          </NativeTouchableOpacity>
        </View>
        <View style={{width: calcActualSize(1), backgroundColor: '#E8EAEE'}} />
        <View
          style={[
            theme.flex.flex,
            theme.flex.col,
            theme.flex.centerByCol,
            {gap: calcActualSize(12)},
          ]}>
          <NativeTouchableOpacity onPress={toWithdraw}>
            <View
              style={[
                theme.flex.flex,
                theme.flex.row,
                theme.flex.centerByCol,

                {gap: calcActualSize(12)},
              ]}>
              <LazyImage
                imageUrl={withdrawIcon}
                width={walletIconSize}
                height={walletIconSize}
              />
              <Text calc main fontSize={14} fontWeight="700">
                {i18n.t('me.money.withdraw')}
              </Text>
            </View>
          </NativeTouchableOpacity>

          <NativeTouchableOpacity onPress={toWithdrawRecord}>
            <View
              style={[
                theme.flex.row,
                theme.flex.centerByCol,
                {gap: calcActualSize(4)},
              ]}>
              <RecordIcon
                width={calcActualSize(20)}
                height={calcActualSize(20)}
                style={{color: theme.fontColor.accent}}
              />
              <Text calc second fontSize={12}>
                {i18n.t('me.label.withdrawRecord')}
              </Text>
            </View>
          </NativeTouchableOpacity>
        </View>
      </View>
      {/* <View
        style={[
          theme.padding.lrl,
          theme.flex.row,
          // theme.flex.between,
          theme.flex.around,
          theme.flex.centerByCol,
          theme.margin.topm,
        ]}>
        <NativeTouchableOpacity onPress={toRecharge}>
          <View style={[theme.flex.col, theme.flex.center, amountStyle.item]}>
            <LazyImage
              occupancy="#0000"
              imageUrl={rechargeIcon}
              width={walletIconSize}
              height={walletIconSize}
            />
            <Text calc style={[theme.font.second, theme.padding.topxxs]}>
              {i18n.t('me.money.recharge')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity onPress={toWithdraw}>
          <View style={[theme.flex.col, theme.flex.center, amountStyle.item]}>
            <LazyImage
              occupancy="#0000"
              imageUrl={withdrawIcon}
              width={walletIconSize}
              height={walletIconSize}
            />
            <Text calc style={[theme.font.second, theme.padding.topxxs]}>
              {i18n.t('me.money.withdraw')}
            </Text>
          </View>
        </NativeTouchableOpacity>
      </View> */}
    </View>
  );
};

export default MeAmount;
